import { useState } from 'react'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import FormattedNumber, { NumberFormatValues } from 'react-number-format'

interface IProps {
  buttonText: string
  placeHolder?: string
  suffix?: string
  onButtonClick: (input: number) => void
}

const CurrencyInputWithButton: React.FC<IProps> = ({ buttonText, placeHolder, suffix, onButtonClick }: IProps) => {
  const [inputValue, setValue] = useState(0)

  const handleKeyPress = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.key === 'Enter') {
      onButtonClick(inputValue)
    }
  }

  return (
    <SCWrapper>
      <SCFormattedInput
        suffix={suffix || ' kr.'}
        onValueChange={(values: NumberFormatValues) => setValue(values.floatValue || 0)}
        thousandSeparator="."
        decimalSeparator=", "
        allowNegative={false}
        placeholder={placeHolder || 'Sláðu inn kaupverð'}
        type="tel"
        onKeyDown={handleKeyPress}
      />

      <SCButton onClick={() => onButtonClick(inputValue)}>{buttonText}</SCButton>
    </SCWrapper>
  )
}

export default CurrencyInputWithButton

const SCWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: row;
    border: 0.0625rem solid ${colors.borderGrey};
    border-radius: 0.75rem;
  `}
`

const SCFormattedInput = styled(FormattedNumber)`
  margin-left: 1rem;
  border: none;
  flex-grow: 2;
  ${Typography.inputText};
  font-weight: 400;
  &:focus-visible {
    outline: transparent;
  }
`

const SCButton = styled.button`
  ${({ theme: { colors } }) => css`
    ${Typography.buttonText};
    width: 8rem;
    height: 4rem;
    margin: 0.5rem;
    background-color: ${colors.orange400};
    border-radius: 0.5rem;
    border: none;
    transition: background-color 320ms ease;
    &:hover {
      background-color: ${colors.buttonHover};
    }
  `}
`
