import { useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import MainGridContainer from '../grid/MainGridContainer'
import { SCContentColumn, SCImageColumn } from '../imageSection/ImageSection'
import { IFrontPage } from '../../prismicTypes'
import Typography from '../../styles/typography'
import CurrencyInputWithButton from '../CurrencyInputWithButton'
import { useRouteChange } from '../RoutingProvider'
import { convertNumberToCurrency, replaceDoubleHyphen } from '../../utils/helpers'
import { calculatorConstants } from '../../data/Constants'
import { updateDeposit, updatePrice } from '../../store/loan'
import { linkResolver } from '../../utils/prismicHelpers'
// import IconLink from '../IconLink'
import { CALCULATOR_ID } from '../slices/CalculatorCard'
import PrismicRichText from '../PrismicRichText'

interface IProps {
  data: IFrontPage.IData
}

const FrontpageHero: React.FC<IProps> = ({ data: { title, image, description, calculator_site } }: IProps) => {
  const { handleRouteChange } = useRouteChange()
  const dispatch = useDispatch()

  const [error, setError] = useState('')

  const handleOnClick = (input: number) => {
    if (input > calculatorConstants.MAX_PRICE_AMOUNT) {
      setError(`Hámarksupphæð er ${convertNumberToCurrency(calculatorConstants.MAX_PRICE_AMOUNT)}`)
    } else if (input < calculatorConstants.MIN_PRICE_AMOUNT) {
      setError(`Lágmarksupphæð er ${convertNumberToCurrency(calculatorConstants.MIN_PRICE_AMOUNT)}`)
    } else {
      dispatch(updatePrice(input))
      dispatch(updateDeposit(input / 2))
      handleRouteChange({ url: `${linkResolver(calculator_site, CALCULATOR_ID)}` || '', isNextLink: true })
    }
  }

  return (
    <MainGridContainer extraStyle={SCGrid}>
      <SCContentColumn alignImageRight={true}>
        <SCContent>
          <SCTitle>{replaceDoubleHyphen(title[0].text)}</SCTitle>
          <SCDescription>
            <PrismicRichText extraParagraphStyle={Typography.pBody} richText={description} />
          </SCDescription>
          <SCInputWrapper>
            <CurrencyInputWithButton buttonText="Reikna lán" onButtonClick={handleOnClick} />
            {error && <SCErrorMessage>{error}</SCErrorMessage>}
          </SCInputWrapper>

          {/* This will be re-added once we have the chat implemented.
          <IconLink icon={'questionBubbles'} text={hero_link_text} link={hero_link} font="normal" /> 
          */}
        </SCContent>
      </SCContentColumn>
      <SCImageColumn alignImageRight={true} hideImageOnTablet={true}>
        <SCImage imageUrl={image.url} />
      </SCImageColumn>
    </MainGridContainer>
  )
}

export default FrontpageHero

const SCGrid = css`
  ${({ theme: { breakpoints } }) => css`
    margin: 5rem 0;
    overflow: hidden;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 10rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      overflow: visible;
      margin-bottom: 15rem;
    }
  `}
`

const SCTitle = styled.h3`
  ${({ theme: { breakpoints } }) => css`
    ${Typography.h1}
    margin-bottom: 1rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 1.5rem;
    }
  `}
`

const SCDescription = styled.div`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 2rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 2.75rem;
    }
  `}
`

const SCContent = styled.div`
  display: flex;
  flex-direction: column;
`

const SCImage = styled.div<{ imageUrl: string }>`
  ${({ imageUrl }) => css`
    background-image: url(${imageUrl});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.625rem;
    height: 100%;
  `}
`

const SCInputWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 2rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 2.625rem;
    }
  `}
`

const SCErrorMessage = styled.span`
  ${({ theme: { colors } }) => css`
    ${Typography.pBody}
    color: ${colors.red400};
    font-weight: 700;
    margin-left: 0.5rem;
  `}
`
