import React from 'react'
import SliceZone from '../components/slices/SliceZone'
import FrontpageHero from '../components/Hero/FrontpageHero'
import { getFrontPageData } from '../utils/prismicRouteHelpers'
import { IDocumentSlice, IFrontPage } from '../prismicTypes'
import { getFrontPageSlicesFromDocument } from '../utils/prismicHelpers'
import { getFrontPageSEOData } from '../utils/seoHelpers'
import NextHeader from '../components/NextHeader'

interface IProps {
  frontpage: IFrontPage.IProps
  sliceDocuments: {
    results: IDocumentSlice[]
  }
}

const Index: React.FC<IProps> = ({ frontpage: { data } }) => {
  return (
    <>
      <NextHeader seoData={getFrontPageSEOData()} />
      <FrontpageHero data={data} />
      <SliceZone slices={data.body} />
    </>
  )
}

export const getStaticProps = async () => {
  const {
    props: { frontpage, notificationbanner, navigation, footer },
  } = await getFrontPageData('')

  const frontpageSlices = await getFrontPageSlicesFromDocument(frontpage)

  return {
    props: {
      frontpage: { ...frontpage, data: { ...frontpage.data, body: frontpageSlices } },
      notificationbanner,
      navigation,
      footer,
    },
  }
}

export default Index
